import React,{FunctionComponent} from 'react';
import {Link,graphql, PageProps} from 'gatsby';
import Layout from '../../components/layout/Layout';

type DataType = {
    allWpPost: {
        nodes: Array<{
            slug: string;
            title: string;
            excerpt: string;
        }>;
    }
};

const BlogIndexPage: FunctionComponent<PageProps<DataType>> = ({
    data
}) => {
    const posts = data.allWpPost.nodes;

    return <Layout title={'Blog'}>
        {posts.map(post =>
            <div key={post.slug}>
                <h2>{post.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                <Link to={`/blog/${post.slug}`}>Saznajte više...</Link>
            </div>
        )}
    </Layout>
};

export const pageQuery = graphql`
    query GetBlogPosts {
        allWpPost(sort: {fields:[date], order:DESC}) {
            nodes {
                slug
                title
                excerpt
            }
        }
    }
`;

export default BlogIndexPage;
